import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const CAKE_PER_BLOCK = new BigNumber(1)
export const BLOCKS_PER_YEAR = new BigNumber(14892000)
export const BSC_BLOCK_TIME = 3

export const CAKE_POOL_PID = 2

export const GAME_POOLS = [1, 2, 3, 4]
export const NFT_POOLS = [1, 2, 3, 4]
