import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import sousChefV2ABI from 'config/abi/sousChefV2.json'
import { GAME_POOLS } from 'config'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import erc20 from 'config/abi/erc20.json'
import { QuoteToken } from 'config/constants/types'
import multicall from 'utils/multicall'
import { getWbnbAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'endBlock',
    }
  })

  const starts = await multicall(sousChefABI, callsStartBlock)
  const ends = await multicall(sousChefABI, callsEndBlock)

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsTotalStatking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingTokenName !== QuoteToken.BNB)
  const bnbPool = poolsConfig.filter((p) => p.stakingTokenName === QuoteToken.BNB)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: poolConfig.stakingTokenAddress,
      name: 'balanceOf',
      params: [poolConfig.contractAddress[CHAIN_ID]],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [poolConfig.contractAddress[CHAIN_ID]],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

export const fetchPoolsCanWithdraw = async () => {
  const callsCanWithdraw = poolsConfig.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'canWithdraw',
    }
  })

  const canWithdraws = await multicall(sousChefABI, callsCanWithdraw)

  return poolsConfig.map((cakePoolConfig, index) => {
    const canWithdraw = canWithdraws[index]
    return {
      sousId: cakePoolConfig.sousId,
      canWithdraw: canWithdraw[0],
    }
  })
}

export const fetchPoolsRewardPrices = async () => {
  const callsTokenBalanceLP = poolsConfig.map((poolConfig) => {
    return {
      address: poolConfig.tokenAddress[CHAIN_ID],
      name: 'balanceOf',
      params: [poolConfig.lpAddress[CHAIN_ID]],
    }
  })

  const callsQuoteTokenBalanceLP = poolsConfig.map((poolConfig) => {
    return {
      address: poolConfig.quoteTokenAdress[CHAIN_ID],
      name: 'balanceOf',
      params: [poolConfig.lpAddress[CHAIN_ID]],
    }
  })

  const tokenBalanceLPs = await multicall(erc20, callsTokenBalanceLP)
  const quoteTokenBalanceLPs = await multicall(erc20, callsQuoteTokenBalanceLP)

  return poolsConfig.map((cakePoolConfig, index) => {
    const tokenBalanceLP = tokenBalanceLPs[index]
    const quoteTokenBalanceLP = quoteTokenBalanceLPs[index]

    const tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))

    return {
      sousId: cakePoolConfig.sousId,
      tokenPriceVsQuote: new BigNumber(tokenPriceVsQuote).toJSON(),
    }
  })
}

export const fetchPoolsPredictionGames = async () => {
  const poolsConfigV2 = poolsConfig.filter((pool) => GAME_POOLS.includes(pool.sousId))

  const callsRoundCounter = poolsConfigV2.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'roundCounter',
    }
  })

  const roundCounters = await multicall(sousChefV2ABI, callsRoundCounter)

  const callsLastRounds = poolsConfigV2.map((poolConfig, index) => {
    const roundCounter = new BigNumber(roundCounters[index]).minus(1)
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'rounds',
      params: [BigNumber.maximum(roundCounter, 0).toString()]
    }
  })

  const lastRounds = await multicall(sousChefV2ABI, callsLastRounds)

  const callsCurrentRounds = poolsConfigV2.map((poolConfig, index) => {
    const roundCounter = new BigNumber(roundCounters[index])
    return {
      address: poolConfig.contractAddress[CHAIN_ID],
      name: 'rounds',
      params: [BigNumber.maximum(roundCounter, 0).toString()]
    }
  })

  const currentRounds = await multicall(sousChefV2ABI, callsCurrentRounds)

  return poolsConfigV2.map((cakePoolConfig, index) => {
    const roundCounter = roundCounters[index]
    const lastRound = lastRounds[index]
    const currentRound = currentRounds[index]

    return {
      sousId: cakePoolConfig.sousId,
      roundCounter: new BigNumber(roundCounter).toJSON(),
      lastRoundPositionWinner: new BigNumber(lastRound.positionWinner).toJSON(),
      roundStart: new BigNumber(currentRound.roundStart._hex).toJSON(),
      lockPrice: new BigNumber(currentRound.lockPrice._hex).toJSON(),
    }
  })
}
