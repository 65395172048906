import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 4,
    tokenName: 'MAI',
    stakingTokenName: 'DESPAIR',
    stakingTokenAddress: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    contractAddress: {
      137: '0xC54e8756cA0A6Bd703C791631e479D6d4A1693D1',
    },
    lpAddress: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenAddress: {
      137: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mai.finance/',
    harvest: true,
    tokenPerBlock: '0.01666666667',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 3000,
    withdrawLockup: 120,
    harvestLockup: 4,
    partnerColor: '254, 134, 140',
    experienceBalance: 20000,
  },
  {
    sousId: 3,
    tokenName: 'PEAR',
    stakingTokenName: 'DESPAIR',
    stakingTokenAddress: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    contractAddress: {
      137: '0x33d54D94CEC53736Bf6F6bBE9dD03477FF73a14F',
    },
    lpAddress: {
      137: '0xE35FBe6BFfb5dd0FA1A81D07967b4d6E6bcEDf4c',
    },
    tokenAddress: {
      137: '0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://app.pearzap.com/',
    harvest: true,
    tokenPerBlock: '0.1827222222',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 120,
    harvestLockup: 4,
    partnerColor: '91, 33, 179',
    experienceBalance: 20000,
    tokenRewardTax: 2,
  },
  {
    sousId: 2,
    tokenName: 'SAGE',
    stakingTokenName: 'DESPAIR',
    stakingTokenAddress: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    contractAddress: {
      137: '0x25b3F276f4D02aD56229D79a0B6567dEe94136cb',
    },
    lpAddress: {
      137: '0x70c096187F8a9ac03E34a0e7ed41bCd516878158',
    },
    tokenAddress: {
      137: '0x2ed945Dc703D85c80225d95ABDe41cdeE14e1992',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polysage.finance/',
    harvest: true,
    tokenPerBlock: '0.0001305555556',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 120,
    harvestLockup: 4,
    partnerColor: '75, 69, 190',
    experienceBalance: 20000,
  },
  {
    sousId: 1,
    tokenName: 'DAI',
    stakingTokenName: 'DESPAIR',
    stakingTokenAddress: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    contractAddress: {
      137: '0xb1544744c100e0359d0620218Aae8F3a891bF53f',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.02777777778',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 3000,
    withdrawLockup: 72,
    harvestLockup: 4,
    partnerColor: '119, 81, 216',
    experienceBalance: 30000,
  },
]

export default pools
