import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import useBlock from 'hooks/useBlock'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
// import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Pools = lazy(() => import('./views/Pools'))
const NftAirdrop = lazy(() => import('./views/NftAirdrop'))
const NftSale = lazy(() => import('./views/NftSale'))
const NftRanking = lazy(() => import('./views/NftRanking'))
const JetFuelVaults = lazy(() => import('./views/JetFuelVaults'))
// const Lottery = lazy(() => import('./views/Lottery'))
const TheEndless = lazy(() => import('./views/TheEndless'))
const Tutorials = lazy(() => import('./views/Tutorials'))
const Team = lazy(() => import('./views/Team'))
const MyNft = lazy(() => import('./views/MyNft'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()

  const currentBlock = useBlock()

  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router>
      <div id="bg" />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home currentBlock={currentBlock} />
            </Route>
            <Route path="/farms">
              <Farms currentBlock={currentBlock} />
            </Route>
            <Route path="/pools">
              <Farms tokenMode currentBlock={currentBlock} />
            </Route>
            <Route path="/nft-airdrop">
              <NftAirdrop currentBlock={currentBlock} />
            </Route>
            <Route path="/nft-sale">
              <NftSale currentBlock={currentBlock} />
            </Route>
            <Route path="/nft-ranking">
              <NftRanking />
            </Route>
            <Route path="/vaults">
              <JetFuelVaults />
            </Route>
            <Route path="/gray-kingdom">
              <Pools />
            </Route>
            <Route path="/nft-cards">
              <MyNft />
            </Route>
            <Route path="/the-endless">
             <TheEndless />
            </Route>
            <Route path="/tutorials">
             <Tutorials />
            </Route>
            <Route path="/team">
             <Team />
            </Route>
            {/* <Route path="/lottery"> */}
            {/* <Lottery /> */}
            {/* </Route> */}
            {/* <Route path="/nft"> */}
            {/*  <Nft /> */}
            {/* </Route> */}
            {/* Redirect */}
            {/* <Route path="/staking"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* <Route path="/syrup"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      {/* <NftGlobalNotification /> */}
    </Router>
  )
}

export default React.memo(App)
