import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 0,
    farmPid: 2,
    isTokenOnly: true,
    lpSymbol: 'DESPAIR',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'USDC',
    earnDecimal: 6,
    from: 'DESPAIR',
    abiFile: 'vaultDespairStrategy',
    strategyAddresses: {
      137: '0x394dC858E8637DA42E38D60AC1279CaF23460E08',
    },
    compoundFrequency: 288,
    performanceFee: 0,
  },
  {
    pid: 2,
    farmPid: 1,
    lpSymbol: 'DESPAIR-MATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x6E01a7E55987Bc195b54FBbdaAf40e57544b23C3',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'DESPAIR-MATIC',
    earnDecimal: 18,
    from: 'DESPAIR',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x205ddb0DbB51D3b9225f362899e6Abd6E14C5032',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 3,
    farmPid: 0,
    lpSymbol: 'DESPAIR-USDC',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESPAIR-USDC',
    earnDecimal: 18,
    from: 'DESPAIR',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x6b77dEf1D1c17962CD0D2E321816B1E9381DACd9',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
]

export default vaults
