/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import poolsConfig from 'config/constants/pools'
import { fetchPoolsBlockLimits, fetchPoolsTotalStatking, fetchPoolsCanWithdraw, fetchPoolsRewardPrices, fetchPoolsPredictionGames } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserNextHarvestUntils,
  fetchUserPendingRewards,
  fetchUserCanHarvests,
  fetchUserIsPlayingNow,
  fetchCanClaimHarvest,
  fetchUserRoundNumbers,
  fetchUserLastRoundHarvets,
  fetchUserPositions,
  fetchUserNftIds,
  fetchUserHasNfts,
  fetchUserNftExperiences,
} from './fetchPoolsUser'
import { PoolsState, Pool } from '../types'

const initialState: PoolsState = { data: [...poolsConfig] }

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)
      state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
    },
  },
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch) => {
  const blockLimits = await fetchPoolsBlockLimits()
  const totalStakings = await fetchPoolsTotalStatking()
  const canWithdraws = await fetchPoolsCanWithdraw()
  const rewardPrices = await fetchPoolsRewardPrices()
  const predictionGames = await fetchPoolsPredictionGames()

  const liveData = poolsConfig.map((pool) => {
    const blockLimit = blockLimits.find((entry) => entry.sousId === pool.sousId)
    const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)
    const canWithdraw = canWithdraws.find((entry) => entry.sousId === pool.sousId)
    const rewardPrice = rewardPrices.find((entry) => entry.sousId === pool.sousId)
    const predictionGame = predictionGames.find((entry) => entry.sousId === pool.sousId)
    return {
      ...blockLimit,
      ...totalStaking,
      ...canWithdraw,
      ...rewardPrice,
      ...predictionGame,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsUserDataAsync = (account) => async (dispatch) => {
  const allowances = await fetchPoolsAllowance(account)
  const stakingTokenBalances = await fetchUserBalances(account)
  const stakedBalances = await fetchUserStakeBalances(account)
  const nextHarvestUntils = await fetchUserNextHarvestUntils(account)
  const pendingRewards = await fetchUserPendingRewards(account)
  const canHarvests = await fetchUserCanHarvests(account)
  const userIsPlayingNow = await fetchUserIsPlayingNow(account)
  const canClaimHarvest = await fetchCanClaimHarvest(account)
  const roundNumbers = await fetchUserRoundNumbers(account)
  const lastRoundHarvests = await fetchUserLastRoundHarvets(account)
  const positions = await fetchUserPositions(account)
  const nftIds = await fetchUserNftIds(account)
  const hasNfts = await fetchUserHasNfts(account)
  const nftExperiences = await fetchUserNftExperiences(account)

  const userData = poolsConfig.map((pool) => ({
    sousId: pool.sousId,
    allowance: allowances[pool.sousId],
    stakingTokenBalance: stakingTokenBalances[pool.sousId],
    stakedBalance: stakedBalances[pool.sousId],
    nextHarvestUntil: nextHarvestUntils[pool.sousId],
    pendingReward: pendingRewards[pool.sousId],
    canHarvest: canHarvests[pool.sousId],
    userIsPlayingNow: userIsPlayingNow[pool.sousId],
    canClaimHarvest: canClaimHarvest[pool.sousId],
    roundNumber: roundNumbers[pool.sousId],
    lastRoundHarvest: lastRoundHarvests[pool.sousId],
    position: positions[pool.sousId],
    nftId: nftIds[pool.sousId],
    hasNft: hasNfts[pool.sousId],
    nftExperience: nftExperiences[pool.sousId],
  }))

  dispatch(setPoolsUserData(userData))
}

export const updateUserAllowance = (sousId: string, account: string) => async (dispatch) => {
  const allowances = await fetchPoolsAllowance(account)
  dispatch(updatePoolsUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
}

export const updateUserBalance = (sousId: string, account: string) => async (dispatch) => {
  const tokenBalances = await fetchUserBalances(account)
  dispatch(updatePoolsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }))
}

export const updateUserStakedBalance = (sousId: string, account: string) => async (dispatch) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  dispatch(updatePoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }))
}

export const updateUserPendingReward = (sousId: string, account: string) => async (dispatch) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  dispatch(updatePoolsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
}

export const updateUserCanHarvest = (sousId: string, account: string) => async (dispatch) => {
  const canHarvests = await fetchUserCanHarvests(account)
  dispatch(updatePoolsUserData({ sousId, field: 'canHarvest', value: canHarvests[sousId] }))
}

export const updateUserIsPlayingNow = (sousId: string, account: string) => async (dispatch) => {
  const isPlayingNow = await fetchUserIsPlayingNow(account)
  dispatch(updatePoolsUserData({ sousId, field: 'userIsPlayingNow', value: isPlayingNow[sousId] }))
}

export const updateCanClaimHarvest = (sousId: string, account: string) => async (dispatch) => {
  const canClaimHarvest = await fetchCanClaimHarvest(account)
  dispatch(updatePoolsUserData({ sousId, field: 'canClaimHarvest', value: canClaimHarvest[sousId] }))
}

export const updateLedger = (sousId: string, account: string) => async (dispatch) => {
  const roundNumbers = await fetchUserRoundNumbers(account)
  const lastRoundHarvests = await fetchUserLastRoundHarvets(account)
  const positions = await fetchUserPositions(account)

  dispatch(updatePoolsUserData({ sousId, field: 'roundNumber', value: roundNumbers[sousId] }))
  dispatch(updatePoolsUserData({ sousId, field: 'lastRoundHarvest', value: lastRoundHarvests[sousId] }))
  dispatch(updatePoolsUserData({ sousId, field: 'position', value: positions[sousId] }))
}

export default PoolsSlice.reducer
